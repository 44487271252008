<template>
  <div class="PaySuccessBox">
    <p class="p_line"></p>
    <div class="PaySuccess">
      <el-row class="SuccessBox">
        <el-col :span="17" class="SuccessBoxLeft">
            <p>
                <i class="el-icon-circle-check"></i>支付成功！我们会尽快为您发货！
            </p>
            <p>订单ID：{{sn}}</p>
            <p>订单金额：{{total}}</p>
            <p>下单时间：{{creatTime}}</p>
            <div>
                <span  @click="jumpShopFun">继续购物</span>
                <span  @click="jumpOrderFun">查看我的订单>></span>
            </div>
        </el-col>
        <el-col :span="7" class="SuccessBoxRight">
            <div>
                <img :src="IosCode" alt="IosCode">
                <img :src="AZCode" alt="AZCode">
            </div>
            <p class="text-center">下载大药房订货系统手机APP,实时跟踪订单状态></p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import IosCode from "assets/3Aios_down.png";
import AZCode from "assets/3Aaz_down.png";
import Qs from "qs";
export default {
  name: "PaySuccess",
  data() {
    return {
        sn:'',
        total:0,
        creatTime:'',
         IosCode: IosCode,
      AZCode:AZCode,
    };
  },
  methods: {
    jumpShopFun(){
       this.$router.push({name:"Home"})
    },
     jumpOrderFun(){
       this.$router.push({name:"MyOrder"})
    },
  },
  created() {
      
      
  },
  wacth: {},
  mounted() {
      if(this.$route.query.datas=='[object Object]'){
        let orderDatas=Qs.parse(sessionStorage.getItem("orderDatas"))
        this.sn=orderDatas.sn
        this.total=orderDatas.total
        this.creatTime=orderDatas.creatTime.replace("am","上午").replace("pm","下午")
      }else{
        this.sn=this.$route.query.datas.sn
        this.total=this.$route.query.datas.total
        this.creatTime=this.$route.query.datas.creatTime.replace("am","上午").replace("pm","下午")
      }
  },
  updated() {},
  components: {},
};
</script>
<style lang="less" scoped>
.PaySuccessBox {
  padding: 20px 0;
  height: 600px;
  .p_line {
    height: 3px;
    background: #FFD303;
  }
  .PaySuccess {
    height: 260px;
    background: #fff;
  }
  .SuccessBox{
      width:900px;
      height: 100%;
      padding: 42px 0;
      margin: 0 auto;
      .SuccessBoxLeft{
          div{
              padding-left: 38px;
              margin-top: 22px;
              span:nth-child(1){
                width: 150px;
                display: block;
                height: 40px;
                text-align: center;
                line-height: 40px;
                background: #FFD303;
                color: #ffffff;
                font-size: 16px;
                border-radius: 4px;
                margin-right: 16px;
                float: left;
              }
                span:nth-child(2){
                    float: left;
                    line-height: 40px;
                    color:#666666;
                    font-size:12px
                }
          }
          p{
            text-indent: 38px;
            position: relative;
            color: #333333;
              text-align: left;
              font-size:12px;
              line-height:22px
          }
          p:nth-child(1){
              line-height: 28px;
              color: #333333;
              text-align: left;
              font-size: 20px;
              font-weight: bold;
              margin-bottom: 20px;
            i{
                position: absolute;
                font-size: 28px;
                color: #FA8C16;
                left: -38px;
                bottom: -2px;
                font-weight: bold;
            }
          }
      }
     .SuccessBoxRight{
         div{
             width:100%;
             height:150px;
             margin: 0 auto;
             img:nth-child(1){
                 width:126px;
                 height: 100%;
                 float: left;
             }
             img:nth-child(2){
                 width:126px;
                 height: 100%;
                 float: right;
             }
         }
         p{
             margin-top:6px;
             color: #FFD303;
             font-size: 12px;
             line-height:40px;
         }
     }
  }
}
</style>
